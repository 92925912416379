import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getApiUrl } from '../../utils'
import { User, UserResponse } from '../types'

export type GetAllUsersForFirmResponse = UserResponse[]

const baseUrl = getApiUrl('users')

export const users = createApi({
  reducerPath: 'usersApi',
  tagTypes: ['Users'],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      headers.set('x-api-key', process.env.REACT_APP_X_API_KEY)

      return headers
    },
    credentials: 'include',
    mode: 'cors'
  }),
  endpoints: ({ query, mutation }) => ({
    getAllUsersForFirm: query<GetAllUsersForFirmResponse, string>({
      query: () => '/',
      providesTags: ['Users'],
    }),
    createNewUser: mutation<User, User>({
      query: (userInfo) => {
        return {
          url: '/create',
          method: 'POST',
          body: userInfo
        }
      }
    })
  })
})

export const { useGetAllUsersForFirmQuery, useCreateNewUserMutation } = users
export const usersApi = users.endpoints