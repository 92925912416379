import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Awarded, Project } from 'state/types';
import { getApiUrl } from '../../utils';

export type GetAwardedResponse = {
  awarded: Awarded
}

export type QuarterAwardData = {
  projects: { project: Project, award: Awarded }[]
  total_amount: number;
}

export type FinancialData = {
  quarter_totals: {
    Q1: QuarterAwardData,
    Q2: QuarterAwardData,
    Q3: QuarterAwardData,
    Q4: QuarterAwardData,
  }
  year_total: string;
}

const baseUrl = getApiUrl('api/awarded/')

export const awardeds = createApi({
  reducerPath: 'awardedApi',
  tagTypes: ['Awarded', 'GetAwarded', 'FinancialData'],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      headers.set('x-api-key', process.env.REACT_APP_X_API_KEY)

      return headers
    },
    credentials: 'include',
    mode: 'cors'
  }),
  endpoints: ({ query, mutation }) => ({
    getAwarded: query <GetAwardedResponse, number>({
      query: (projectId) => {
        return `/${projectId}`
      },
      providesTags: ['GetAwarded']
    }),
    getFinancialData: query<{ financial_data: FinancialData }, number>({
      query: (firmId) => {
        return `/financial_data/${firmId}`
      },
      providesTags: ['FinancialData']
    }),
    createAwarded: mutation<Awarded, { awarded_amount: number; projectId: number }>({
      query: ({ awarded_amount, projectId }) => {
        return {
          url: `${projectId}`,
          method: 'POST',
          body: {
            awarded_amount,
            projectId
          }
        }
      }
    })
  }),
  keepUnusedDataFor: 0
})

export const {
  useLazyGetAwardedQuery,
  useGetFinancialDataQuery,
  useCreateAwardedMutation
} = awardeds
export const awardedApi = awardeds.endpoints
