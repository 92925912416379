import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { Login, User } from 'state/types';
import { initUserInfo } from '../../pages/create-firm';
import { createOptions, getApiUrl } from '../../utils';
import { useGetNavigation } from '../../utils/hooks';
// import { useCreateNewUserMutation } from '../../state/services';

const initErrorState = {
  isError: false,
  message: ''
}

export const Invite = () => {
  const { navigate } = useGetNavigation()
  // const [createNewUser] = useCreateNewUserMutation()
  const { token, firmName, inviter } = useParams()
  const [userInfo, setUserInfo] = useState<User & Login>(initUserInfo)
  const [errorFromServer, setErrorFromServer] = useState(initErrorState)
  const [success, setSuccess] = useState(false)
  const accept = async () => {
    const URL = getApiUrl(`api/invite/accepted/${token}`)
    const response = await fetch(URL, createOptions('POST', {
      body: {
        user: {
          first_name: userInfo.firstName,
          last_name: userInfo.lastName,
          email: userInfo.email,
          password: userInfo.password,
        }
      }
    }))

    if (!response.ok) {
      alert('error')
    }

    const jsoned = await response.json()

    if (jsoned.error) {
      setErrorFromServer({
        isError: true,
        message: jsoned.error.toLowerCase().includes('invitation expired')
          ? 'Invitation expired, please ask the admin to send the invite again'
          : jsoned.error.toLowerCase().includes('email has already')
            ? jsoned.error
            : 'There was an error processing the user creation, please contact customer support'
      })
      return
    }

    setErrorFromServer(initErrorState)
    setSuccess(true)
  }

  useEffect(() => {
    if (success) {
      navigate('/login')
    }
  }, [navigate, success])

  // TODO: reuse create user fields, etfc
  return (
    <div className="flex flex-col max-w-screen-lg mx-auto mt-[15%]">
      <h1 className="text-center text-lg font-large leading-6 text-gray-800 w-[75%] mx-auto">
        <span className="font-bold">{inviter}</span> from <span className="font-bold">{firmName}</span> has invited you to their Lampside ecosystem! Create your account to enter <span className="font-bold">{firmName}</span>'s Lampside ecosystem.
      </h1>
      <div className="w-[50%] m-auto">
        <label className="text-sm text-gray-700" htmlFor="userFirstName">
          <input
            type="text"
            name="userFirstName"
            id="userFirstName"
            placeholder="First Name*"
            className="block w-full px-4 py-3 mt-4 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40   "
            onBlur={(e) => {
              setUserInfo({
                ...userInfo,
                firstName: e.target.value,
              })
            }}
          />
        </label>
        <label className="text-sm text-gray-700" htmlFor="userLastName">
          <input
            type="text"
            name="userLastName"
            id="userLastName"
            placeholder="Last Name*"
            className="block w-full px-4 py-3 mt-4 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40   "
            onBlur={(e) => {
              setUserInfo({
                ...userInfo,
                lastName: e.target.value,
              })
            }}
          />
        </label>
        <label className="text-sm text-gray-700" htmlFor="userEmail">
          <input
            type="email"
            name="userEmail"
            id="userEmail"
            placeholder="Email*"
            className="block w-full px-4 py-3 mt-4 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40   "
            onBlur={(e) => {
              setUserInfo({
                ...userInfo,
                email: e.target.value,
              })
            }}
          />
        </label>
        <label className="text-sm text-gray-700" htmlFor="userPassword">
          <input
            type="password"
            name="userPassword"
            id="userPassword"
            placeholder="Password*"
            className="block w-full px-4 py-3 mt-4 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40   "
            onBlur={(e) => {
              setUserInfo({
                ...userInfo,
                password: e.target.value,
              })
            }}
          />
        </label>
        <button
          type="button"
          className="w-full px-4 py-2 mt-4 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-md sm:w-full hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
          onClick={async () => {
            // TODO: Validation
            if (userInfo.firstName === '' || userInfo.lastName === '' || userInfo.email === '' || userInfo.password === '') {
              alert('You must fill out all the inputs to create a user')
              return
            }
            await accept()
          }}
        >
          Create user for {firmName}
        </button>
      </div>
      <div>
        {
          errorFromServer.isError
            ? (
              <div className="mt-6">
                <span className="text-red-500 font-bold text-lg">
                  {errorFromServer.message}
                </span>
              </div>
            )
            : null
        }
      </div>
    </div>
  )
}