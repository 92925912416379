import React, { useEffect, useState } from 'react'

import { useGetAllProjectsQuery, useGetFinancialDataQuery } from '../../state/services'
import type { FinancialData, QuarterAwardData } from '../../state/services/awarded'
import { Chart } from './chart';

const TEMP_2024_QUARTERS = [1, 2, 3]

export const Dashboard = () => {
  const queryProjectsData = useGetAllProjectsQuery('', { refetchOnMountOrArgChange: true })
  const firmId = queryProjectsData.isSuccess ? queryProjectsData.data.user.firm_id : undefined
  const queryFinancialData = useGetFinancialDataQuery(firmId, { skip: !queryProjectsData.isSuccess, refetchOnMountOrArgChange: true })

  const [menuPosition, setMenuPosition] = useState<{ x: number; y: number } | null>(null);
  const [selectedRow, setSelectedRow] = useState<QuarterAwardData | null>(null);
  const [tableData, setTableData] = useState<QuarterAwardData[]>([]);

  useEffect(() => {
    if (queryFinancialData.isSuccess && tableData.length === 0) {

      setTableData(Object.values(queryFinancialData.data.financial_data.quarter_totals))
    }
  }, [queryFinancialData, tableData.length])

  // const handleContextMenu = (event: React.MouseEvent<HTMLTableRowElement>, row: QuarterAwardData) => {
  //   event.preventDefault();
  //   setMenuPosition({ x: event.clientX, y: event.clientY });
  //   setSelectedRow(row);
  // };


  return (

    <Chart data={tableData} />
  )
}