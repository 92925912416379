import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getApiUrl } from '../../utils';
import { Payment } from '../types';

type PaymentPayload = {
  id?: number
  invoice_id: number
  payment_amount: number
  payment_date: number
}

const baseUrl = getApiUrl('api/payments')

// Define a service using a base URL and expected endpoints
export const payments = createApi({
  reducerPath: 'paymentsApi',
  tagTypes: ['Payments'],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      headers.set('x-api-key', process.env.REACT_APP_X_API_KEY)
      // headers.set('Content-Type', 'application/json')

      return headers
    },
    credentials: 'include',
    mode: 'cors'
  }),
  endpoints: ({ query, mutation }) => ({
    getInvoice: query<Payment, string>({
      query: (projectId) => {
        return `/${projectId}`
      },
      providesTags: ['Payments'],
    }),
    createPayment: mutation<Payment, PaymentPayload>({
      query: (payment) => {
        return {
          url: `/${payment.invoice_id}`,
          method: 'POST',
          body: {
            payment_amount: payment.payment_amount,
            payment_date: payment.payment_date,
          }
        }
      },
      invalidatesTags: ['Payments']
    }),
    cancelPayment: mutation<Payment, Omit<PaymentPayload, 'payment_amount' | 'payment_date'>>({
      query: (payment) => {
        return {
          url: `/cancel_payment/${payment.id}/${payment.invoice_id}`,
          method: 'PATCH'
        }
      }
    })
    // updateInvoice: mutation<Invoice, Partial<Invoice>>({
    //   query: (updatedInvoice) => ({
    //     url: `${updatedProject.id}`,
    //     method: 'PATCH',
    //     body: updatedProject,
    //   }),
    //   invalidatesTags: ['Projects']
    // }),
  }),
  keepUnusedDataFor: 0
})

export const { useCreatePaymentMutation, useCancelPaymentMutation } = payments
export const paymentsApi = payments.endpoints