import React, { useEffect } from 'react';
import { useListenForKeyboardPress } from '../../utils/hooks';
import './modal.css';

interface CloseIconProps {
  onClick: (e) => void;
}

type Props = {
  title?: string;
  closeModal: () => void;
  CloseIcon?: React.FC<CloseIconProps>;
  modalHeight?: string;
  children: JSX.Element;
}

export const Modal = ({ title, closeModal, CloseIcon, modalHeight, children }: Props) => {
  useListenForKeyboardPress('Escape', () => { closeModal() })
  useEffect(() => {
    document.body.classList.add('no-scroll')

    return () => {
      document.body.classList.remove('no-scroll')
    }
  }, [])

  return (
    <div className={`absolute top-0 left-0 w-full h-full z-10`}>
      <div className="fixed w-full h-full opacity-25 bg-teal-800" />
      <div
        aria-labelledby="modal-title" role="dialog" aria-modal="true"
      >
        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">&#8203;</span>

          <div
            className={`${closeModal ? 'bg-slate-100 ' : ''}
              fixed
              top-1/2
              left-1/2
              -translate-x-1/2
              -translate-y-1/2
              inline-block
              overflow-hidden
              text-left
              align-bottom
              transition-all
              transform
              bg-white
              rounded-lg
              shadow-xl
              max-w-max
              overflow-visible
              ${modalHeight ?? ''}
              sm:my-8
              sm:w-full
              sm:p-6
              sm:align-middle
              z-10
              `
            }
          // xl:max-w-screen-md
          // sm:max-w-screen-lg
          >
            <div className={`flex justify-between items-center ${title ? 'mb-6' : ''}`}>
              {
                title ?
                  <h3 className="text-xl font-bold leading-6 text-gray-800 capitalize " id="modal-title">
                    {title}
                  </h3>
                  : null
              }
              {CloseIcon ? <CloseIcon onClick={closeModal} /> : null}
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}