import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { Project } from 'state/types';

export interface ProjectState {
  status: Project['status']
}

type InitialState = {
  status: Project['status']
}

const initialState: InitialState = {
  status: 'ACTIVE'
}

export const instanceProjectSlice = createSlice({
  name: 'instanceProject',
  initialState,
  reducers: {
    setInstanceProjectStatus: (state, { payload }: { payload: Pick<ProjectState, 'status'>, type: string }) => {
      state.status = payload.status ?? 'ACTIVE'
    }
  }
})

export const { setInstanceProjectStatus } = instanceProjectSlice.actions

export const selectProject = (state) => state

export const selectProjectStatus = createSelector(selectProject, (state) => {
  return state.instanceProject.status
})