import { Login } from 'state/types'
import { createOptions, getApiUrl } from '../../utils'

// TODO: REFACTOR
export const useLogin = () => {
  const url = getApiUrl('users/login')
  const login = async ({ email, password }: Login) => {
    try {
      const data = await fetch(url, createOptions('POST', {
        body: {
          email, password
        },
      }))

      if (!data.ok) {
        console.error(`useLogin::Login failed with status ${data.status}, check credentials again`)
        throw new Error(`useLogin::Login failed with status ${data.status}, check credentials again`)
      }

      const jsoned = await data.json()

      return jsoned
    } catch (err) {
      throw new Error('useLogin::Error logging in')
    }
  }

  return {
    login
  }
}

export const useLogout = () => {
  const url = getApiUrl('users/logout')
  const logout = async () => {
    try {
      const data = await fetch(url, createOptions('POST'))
      if (!data.ok) {
        throw new Error(`Failed to logout: ${data.status}`)
      }

      // const jsoned = await data.json()
      return
    } catch (err) {
      throw new Error('useLogout::Error logging out')
    }
  }
  return { logout }
}

export const useUpdatePassword = () => {
  const url = getApiUrl('users/update_password')
  const updatePassword = async ({ current_password, new_password }: { current_password: string; new_password: string; }) => {
    try {
      const data = await fetch(url, createOptions('PATCH', {
        body: {
          user: {
            current_password,
            new_password,
          }
        }
      }))

      const jsoned = await data.json()

      if (!data.ok) {
        if (jsoned.errors && jsoned.errors[0].includes('Current password is incorrect')) {
          return 'Current password is incorrect'
        }
        throw new Error(`Failed to update password: ${data.status}`)
      }

      return jsoned.message
    } catch (err) {
      throw new Error('updatePassword::Error updating password')
    }
  }

  return { updatePassword }
}