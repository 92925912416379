import { useState } from "react"
import { useDispatch } from "react-redux"
import { resetAuthState, resetProjectsApi, setLogin } from '../../state/services'
import { resetStore } from '../../state/store'
import { Login } from '../../state/types'
import { useGetNavigation } from '../../utils/hooks'
import { useLogin, useLogout } from "./useLoginLogout"

export const useAuth = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { navigate } = useGetNavigation()
  const { login } = useLogin()
  const { logout } = useLogout()
  const dispatch = useDispatch()


  const handleLoginAll = async (body: Login) => {
    setIsLoading(true)
    // TODO: Refactor all the try/catch
    const response = await login(body)
    dispatch(setLogin(response))

    navigate('/projects')
    setIsLoading(false)
    return response
  }

  const handleLogoutAll = async () => {
    setIsLoading(true)
    // TODO: reset cached data...resetAuthState doesn't work lmao. Or review how we handle all this.
    await logout()
    dispatch(resetAuthState())
    resetStore()
    resetProjectsApi()

    navigate('/login')
    setIsLoading(false)
  }

  return { handleLoginAll, handleLogoutAll, isLoading }

}