import "./styles.css";

import { Color } from "@tiptap/extension-color";
import ListItem from "@tiptap/extension-list-item";
import TextStyle from "@tiptap/extension-text-style";
import { EditorProvider, useCurrentEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import React from "react";

const MenuBar = () => {
  const { editor } = useCurrentEditor();

  if (!editor) {
    return null;
  }

  return (
    <div className="rich-text-editor rounded-md !mb-4 cursor-default flex gap-2 border-black border-b p-4 rounded-none">
      <button
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        className={`bg-gray-200 py-1 px-4 text-gray-700 border-0 rounded-md transition transition-colors hover:bg-gray-700 hover:text-gray-200 ${editor.isActive("bold") ? "is-active" : ""}`}
      >
        bold
      </button>
      <button
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        className={`bg-gray-200 py-1 px-4 text-gray-700 border-0 rounded-md transition transition-colors hover:bg-gray-700 hover:text-gray-200 ${editor.isActive("italic") ? "is-active" : ""}`}
      >
        italic
      </button>
      <button
        onClick={() => editor.chain().focus().toggleStrike().run()}
        disabled={!editor.can().chain().focus().toggleStrike().run()}
        className={`bg-gray-200 py-1 px-4 text-gray-700 border-0 rounded-md transition transition-colors hover:bg-gray-700 hover:text-gray-200 ${editor.isActive("strike") ? "is-active" : ""}`}
      >
        strike
      </button>
      <button
        onClick={() => editor.chain().focus().toggleCode().run()}
        disabled={!editor.can().chain().focus().toggleCode().run()}
        className={`bg-gray-200 py-1 px-4 text-gray-700 border-0 rounded-md transition transition-colors hover:bg-gray-700 hover:text-gray-200 ${editor.isActive("code") ? "is-active" : ""}`}
      >
        code
      </button>
      <button
        onClick={() => editor.chain().focus().setParagraph().run()}
        className={`bg-gray-200 py-1 px-4 text-gray-700 border-0 rounded-md transition transition-colors hover:bg-gray-700 hover:text-gray-200 ${editor.isActive("paragraph") ? "is-active" : ""}`}
      >
        paragraph
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        className={`bg-gray-200 py-1 px-4 text-gray-700 border-0 rounded-md transition transition-colors hover:bg-gray-700 hover:text-gray-200 ${editor.isActive("heading", { level: 1 }) ? "is-active" : ""}`}
      >
        h1
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        className={`bg-gray-200 py-1 px-4 text-gray-700 border-0 rounded-md transition transition-colors hover:bg-gray-700 hover:text-gray-200 ${editor.isActive("heading", { level: 2 }) ? "is-active" : ""}`}
      >
        h2
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        className={`bg-gray-200 py-1 px-4 text-gray-700 border-0 rounded-md transition transition-colors hover:bg-gray-700 hover:text-gray-200 ${editor.isActive("heading", { level: 3 }) ? "is-active" : ""}`}
      >
        h3
      </button>
      {/* <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
        className={`bg-gray-200 py-1 px-4 text-gray-700 border-0 rounded-md transition transition-colors hover:bg-gray-700 hover:text-gray-200 ${editor.isActive("heading", { level: 4 }) ? "is-active" : ""}`}
      >
        h4
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
        className={`bg-gray-200 py-1 px-4 text-gray-700 border-0 rounded-md transition transition-colors hover:bg-gray-700 hover:text-gray-200 ${editor.isActive("heading", { level: 5 }) ? "is-active" : ""}`}
      >
        h5
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
        className={`bg-gray-200 py-1 px-4 text-gray-700 border-0 rounded-md transition transition-colors hover:bg-gray-700 hover:text-gray-200 ${editor.isActive("heading", { level: 6 }) ? "is-active" : ""}`}
      >
        h6
      </button>
       */}
      <button
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={`bg-gray-200 py-1 px-4 text-gray-700 border-0 rounded-md transition transition-colors hover:bg-gray-700 hover:text-gray-200 ${editor.isActive("bulletList") ? "is-active" : ""}`}
      >
        bullet list
      </button>
      <button
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={`bg-gray-200 py-1 px-4 text-gray-700 border-0 rounded-md transition transition-colors hover:bg-gray-700 hover:text-gray-200 ${editor.isActive("orderedList") ? "is-active" : ""}`}
      >
        ordered list
      </button>
      {/* 
      <button
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        className={`bg-gray-200 py-1 px-4 text-gray-700 border-0 rounded-md transition transition-colors hover:bg-gray-700 hover:text-gray-200 ${editor.isActive("codeBlock") ? "is-active" : ""}`}
      >
        code block
      </button> */}
      <button
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        className={`bg-gray-200 py-1 px-4 text-gray-700 border-0 rounded-md transition transition-colors hover:bg-gray-700 hover:text-gray-200 ${editor.isActive("blockquote") ? "is-active" : ""}`}
      >
        blockquote
      </button>
      <button onClick={() => editor.chain().focus().setHorizontalRule().run()} className="bg-gray-200 py-1 px-4 text-gray-700 border-0 rounded-md transition transition-colors hover:bg-gray-700 hover:text-gray-200">
        horizontal rule
      </button>
      <button
        onClick={() => editor.chain().focus().undo().run()}
        disabled={!editor.can().chain().focus().undo().run()}
        className="bg-gray-200 py-1 px-4 text-gray-700 border-0 rounded-md transition transition-colors disabled:bg-slate-150 disabled:cursor-not-allowed disabled:text-white"
      >
        undo
      </button>
      <button
        onClick={() => editor.chain().focus().redo().run()}
        disabled={!editor.can().chain().focus().redo().run()}
        className="bg-gray-200 py-1 px-4 text-gray-700 border-0 rounded-md transition transition-colors disabled:bg-slate-150 disabled:cursor-not-allowed disabled:text-white"
      >
        redo
      </button>
      {/* <button
        onClick={() => editor.chain().focus().setColor("#958DF1").run()}
        className={
          editor.isActive("textStyle", { color: "#958DF1" }) ? "is-active" : ""
        }
      >
        purple
      </button> */}
    </div>
  );
};

const extensions = [
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  TextStyle.configure({ types: [ListItem.name] }),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
  }),
];

type Props = {
  content: string
  updateContent: (content: string) => void
}

export const RichTextEditor = ({ content, updateContent }: Props) => {
  const handleUpdate = ({ editor }) => {
    updateContent(editor.getHTML());
  };

  return (
    <EditorProvider
      slotBefore={<MenuBar />}
      extensions={extensions}
      content={content}
      onUpdate={handleUpdate}
    ></EditorProvider>
  );
};
