import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { resetAuthState } from './auth'

const baseQuery = (url: string) => fetchBaseQuery({
  baseUrl: url,
  prepareHeaders: (headers) => {

    headers.set('x-api-key', process.env.REACT_APP_X_API_KEY)
    return headers
  },
  credentials: 'include',
  mode: 'cors'
})

export const baseQueryWithAuthCheck = (url: string) => async (args, api, extraOptions) => {
  const preppedBaseQuery = baseQuery(url)
  const result = await preppedBaseQuery(args, api, extraOptions)

  if (result.error && result.error.status === 401) {
    // Dispatch the resetAuthState action or any other logic you want to perform
    api.dispatch(resetAuthState())
    // Optionally, you can perform token refresh logic here and retry the original request
  }

  return result
}