import React, { useEffect, useRef, useState } from 'react'
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, TooltipProps } from 'recharts';
import { QuarterAwardData } from 'state/services';
import { APP_BG_SLATE_200, APP_TEXT_BLUE_600 } from '../../utils';
import { useListenForKeyboardPress } from '../../utils/hooks';
import './chart.css'

interface Award {
  id: number;
  project_id: number;
  firm_id: number;
  awarded_number_id: number | null;
  awarded_amount: string;
  awarded_date: string;
  created_at: string;
  updated_at: string;
}

interface Project {
  id: number;
  site: string;
  unit: string;
  street_address: string;
  city: string;
  province: string;
  country: string;
  postal_code: string | null;
  project_type: string;
  status: string;
  fee: string;
  awarded: string;
  comments: string | null;
  project_number: number;
  account_id: number;
  firm_id: number;
  created_at: string;
  updated_at: string;
  fiscal_year: number;
  quarter: number;
}

interface CustomPayload {
  name: string;
  total_amount: number;
  awards: number;
  awardsData: Award[];
  projectsData: Project[];
}


interface CustomTooltipProps extends TooltipProps<number, string> { }

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label }) => {
  const [expandedProjects, setExpandedProjects] = useState<Set<number>>(new Set());

  const formatCurrency = (value: number) => `$${value.toFixed(2)}`;
  const toggleAccordion = (projectId: number) => {
    setExpandedProjects((prevState) => {
      const newState = new Set(prevState);
      if (newState.has(projectId)) {
        newState.delete(projectId);
      } else {
        newState.add(projectId);
      }
      return newState;
    });
  };

  if (active && payload && payload.length) {
    const data = payload[0].payload as CustomPayload;
    return (
      <div className="bg-white p-4 shadow-lg rounded-md">
        {/* Tooltip Header */}
        <div className="font-semibold text-lg text-gray-800">
          Total Amount: <span className="text-blue-600">{formatCurrency(data.total_amount)}</span>
        </div>
        <div className="text-sm text-gray-600">
          <div>Number of Projects: {data.projectsData.length}</div>
          <div>Number of Awards: {data.awardsData.length}</div>
        </div>

        {/* Projects List with Accordion */}
        <div className="mt-4 space-y-2">
          {data.projectsData.map((project) => (
            <div key={project.id} className="border-b py-2">
              {/* Accordion Header with Expand/Collapse Icon */}
              <div
                onClick={() => toggleAccordion(project.id)}
                className="cursor-pointer flex items-center text-blue-600 font-semibold text-md hover:text-blue-800 transition"
              >
                {/* Expand/Collapse Icon */}
                <span className="mr-2 text-lg">
                  {expandedProjects.has(project.id) ? '▼' : '▶'}
                </span>
                {/* Project Site Text */}
                <span className="text-gray-800 text-lg pointer">
                  Project Site: <span className="font-normal">{project.site}</span>
                </span>
              </div>

              {/* Accordion Content (Expandable) */}
              {expandedProjects.has(project.id) && (
                <div className="mt-2 pl-4">
                  <div className="text-sm text-gray-600">
                    <strong>Associated Awards:</strong>
                    <ul>
                      {data.awardsData
                        .filter((award) => award.project_id === project.id)
                        .map((award) => (
                          <li key={award.id}>
                            <span className="text-gray-800">
                              Amount: <span className="font-semibold">{formatCurrency(parseFloat(award.awarded_amount))}</span>
                            </span>
                            <span className="text-gray-500"> | Awarded Date: {new Date(award.awarded_date).toLocaleDateString()}</span>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }
  return null;
};

export const Chart = ({ data }: { data: QuarterAwardData[] }) => {
  const [barChartData, setBarChartData] = useState([]);
  const [tooltipData, setTooltipData] = useState<CustomPayload | null>(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [hoverIndex, setHoverIndex] = useState<string>("");
  const chartContainerRef = useRef<HTMLDivElement | null>(null);
  useListenForKeyboardPress('Escape', () => {
    setTooltipData(null);
  })

  // const handleBarHover = (barData: CustomPayload, e: React.MouseEvent<SVGRectElement>) => {
  //   // Get the relative position of the mouse hover within the container
  //   const rect = chartContainerRef.current?.getBoundingClientRect();
  //   if (rect) {
  //     setTooltipPosition({
  //       x: e.clientX - rect.left + 10, // Add offset to position tooltip a bit to the right
  //       y: e.clientY - rect.top - 30, // Position tooltip above the bar (adjust as needed)
  //     });
  //   }
  //   setTooltipData(barData); // Show tooltip on hover
  // };

  const handleMouseEnter = (name: string) => {
    setHoverIndex(name);
  };

  // Handle mouse leave event (for hover)
  const handleMouseLeave = () => {
    setHoverIndex(null);
  };

  const handleBarClick = (barData: CustomPayload, e: React.MouseEvent<SVGRectElement>) => {
    e.stopPropagation();
    // Toggle tooltip visibility on bar click
    if (tooltipData?.name === barData.name) {
      setTooltipData(null);
    } else {
      setTooltipData(barData);

      const rect = chartContainerRef.current?.getBoundingClientRect();
      if (rect) {
        setTooltipPosition({
          x: e.clientX - rect.left + 10,
          y: e.clientY - rect.top - 30,
        });
      }
    }
  };

  const handleOutsideClick = () => {
    setTooltipData(null); // Close tooltip when clicking outside
  };

  useEffect(() => {
    const chartData = data.map((awardData, awardDataIndex) => {
      return {
        name: `Q${awardDataIndex + 1}`,
        total_amount: Number(awardData.total_amount),
        awards: awardData.projects.length,
        awardsData: awardData.projects.map((projectData) => projectData.award),
        projectsData: awardData.projects.map((projectData) => projectData.project),
      }
    })
    setBarChartData(chartData)
  }, [data])

  if (barChartData.length === 0) return null

  return (
    <div onClick={handleOutsideClick} className="relative w-full" ref={chartContainerRef}>
      <ResponsiveContainer width="100%" height={500}>
        <BarChart
          width={500}
          height={500}
          data={barChartData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          barGap={0}
          barCategoryGap={2}
        >
          <CartesianGrid strokeDasharray="2 2" vertOriginX={0} />
          <XAxis dataKey="name" style={{ fontSize: '1rem' }} />
          <YAxis domain={['dataMin', 'dataMax']} tickFormatter={(val) => `$${val}`} style={{ fontSize: '14px' }} />
          <Tooltip content={() => null} />
          <Legend
            wrapperStyle={{ color: 'green' }}
            formatter={(data) => {
              return "Awarded amount per quarter"
            }}
          />
          <Bar
            onMouseEnter={(props) => {
              handleMouseEnter(props.name)
            }}
            onMouseLeave={handleMouseLeave}
            style={{
              cursor: 'pointer'
            }}
            dataKey="total_amount" fill={APP_TEXT_BLUE_600} barSize={300} activeBar={<Rectangle fill={"#3b82f6"} />} label={(props) => {
              const { x, y, width, height, value } = props;
              const numOfAwards = barChartData[props.index].awards
              const adjustedX = x + width / 2;
              const adjustedY = y + height / 2;
              if (props.value === 0) {
                return null
              }
              const textColor = hoverIndex === props.name ? APP_BG_SLATE_200 : 'white'
              return (
                <text x={adjustedX}
                  y={adjustedY}
                  textAnchor="middle"
                  fill={textColor}
                  fontSize={16}
                  style={{
                    pointerEvents: "none",  // Disable interaction with label
                    overflow: "hidden",     // Ensure label doesn't overflow outside the bar
                    dominantBaseline: "middle",
                  }}>
                  ${value} from {numOfAwards} awards
                </text>
              );
            }}
            onClick={(data, index, event) => {
              const typedData = data as { payload: CustomPayload }
              handleBarClick(typedData.payload, event as React.MouseEvent<SVGRectElement>)
            }}
          />
          {/* <Bar dataKey="awards" fill="#82ca9d" width={1} maxBarSize={1}  barSize={1} activeBar={<Rectangle fill="gold" stroke="purple" />} /> */}
        </BarChart>
      </ResponsiveContainer>
      {tooltipData && (
        <div
          className="absolute bg-white p-4 rounded shadow-lg border"
          style={{ left: tooltipPosition.x, top: tooltipPosition.y }}
          onClick={(e) => e.stopPropagation()}
        >
          <CustomTooltip active={true} payload={[{ payload: tooltipData }]} />
        </div>
      )}
    </div>
  )
}