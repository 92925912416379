import React, { useState } from 'react';
import { useListenForKeyboardPress, useListenForOutsideClick } from '../../utils/hooks';


const moveValueAtStart = (arr, element) => {
  const index = arr.indexOf(element);
  if (index > -1) {
    arr.splice(index, 1); // Remove the element from its original position
    arr.unshift(element); // Add the element to the start of the array
  }
  return arr;
}

type Props = {
  isOpen: boolean
  toggleOpen: () => void
  initValue?: string
  addNew?: boolean
  saveCallback: (value: string) => void
  closeDropdown: () => void
  list: string[]
}

export const DropdownSearch = ({ isOpen, toggleOpen, initValue, addNew, list, saveCallback, closeDropdown }: Props) => {
  useListenForKeyboardPress('Escape', () => {
    closeDropdown()
  })
  const { wrapperRef } = useListenForOutsideClick(closeDropdown)
  const [selected, setSelected] = useState(initValue ?? '')
  const [searchTerm, setSearchTerm] = useState('');
  const [items, setItems] = useState(moveValueAtStart(list, initValue));


  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleAddItem = () => {
    setItems([...items, searchTerm]);
    setSelected(searchTerm); // Set search term
    setSearchTerm(''); // Clear the search input after adding

    setTimeout(() => {
      saveCallback(searchTerm)
    }, 200)
  };

  const handleCheck = (isChecked: boolean, value: string) => {
    if (isChecked) {
      setSelected(value);
    } else {
      setSelected('');
      return
    }

    setTimeout(() => {
      saveCallback(value)
    }, 200)
  }

  const filteredItems = items.filter((item) =>
    item.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const isExactMatch = items.some((item) => item.toLowerCase() === searchTerm.toLowerCase());

  return (
    <div ref={wrapperRef} className="max-w-[120px]">
      {
        <>

          <button
            id={`dropdownSearchButton-${initValue}`}
            data-dropdown-toggle={`dropdownSearch-${initValue}`}
            data-dropdown-placement="bottom"
            className={`capitalize bg-white text-blue-600 border border-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-1 text-center inline-flex items-center`}
            type="button"
            onClick={toggleOpen}
          >
            {selected === '' ? 'Choose below' : selected}
            <svg
              className="w-2.5 h-2.5 ms-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 4 4 4-4"
              />
            </svg>
          </button>
          {
            isOpen
              ? (
                <div
                  id={`dropdownSearch-${initValue}`}
                  className="z-10 bg-white rounded-lg shadow w-60"
                >
                  <div className="p-3">
                    <label htmlFor="input-group-search" className="sr-only">
                      Search
                    </label>
                    <div className="relative">
                      <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                        <svg
                          className="w-4 h-4 text-gray-500"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 20 20"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                          />
                        </svg>
                      </div>
                      <input
                        type="text"
                        id="input-group-search"
                        className="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                        placeholder="Search or add item"
                        value={searchTerm}
                        onChange={handleSearchChange}
                      />
                    </div>
                  </div>
                  <ul
                    className="h-48 px-3 pb-3 overflow-y-auto text-sm text-neutral-100"
                    aria-labelledby={`dropdownSearchButton-${initValue}`}
                  >
                    {filteredItems.map((item, index) => (
                      <li key={index}>
                        <div className="flex items-center ps-2 rounded hover:bg-gray-100">
                          <input
                            id={`checkbox-item-${index}`}
                            type="checkbox"
                            checked={item === selected}
                            value={item}
                            onChange={(e) => {
                              handleCheck(e.target.checked, item);
                            }}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                          />
                          <label
                            htmlFor={`checkbox-item-${index}`}
                            className="w-full py-2 ms-2 text-sm font-medium text-gray-900 rounded"
                          >
                            {item}
                          </label>
                        </div>
                      </li>
                    ))}
                  </ul>
                  {!isExactMatch && searchTerm && addNew && (
                    <div className="flex justify-center p-3">
                      <button
                        onClick={handleAddItem}
                        className="text-white px-5 py-3 text-white duration-150 bg-blue-600 rounded-lg hover:bg-sky-700 active:bg-sky-500"
                      >
                        Add "{searchTerm}"
                      </button>
                    </div>
                  )}
                </div>
              )
              : null
          }

        </>
      }

    </div>
  )
}
