/* eslint-disable no-script-url */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Loader } from '../../components/loader';
import { useAuth } from '../../hooks';
import { Modal } from '../../shared';
import { selectIsLoggedIn } from '../../state/services';
import { useGetNavigation } from '../../utils/hooks';
import LogoTemp from './assets/logo-temp.png';

export const Nav = () => {
  const { navigate, location } = useGetNavigation()
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const { handleLogoutAll, isLoading } = useAuth()

  const [state, setState] = useState(false)

  // Replace javascript:void(0) paths with your paths
  // TODO: Refactor
  const navigation = []
  if (isLoggedIn) {
    navigation.push({ title: "Firm", path: "/firm" })
    navigation.push({ title: "User", path: "/user" })
  }

  navigation.push({ isAuth: true, title: isLoggedIn ? 'Logout' : 'Login', path: isLoggedIn ? '/logout' : '/login' })

  const submenuNav = [
    { title: "Dashboard", path: "/dashboard" },
    { title: "Projects", path: "/projects" },
    { title: "Accounts", path: "/accounts" },
  ]

  if (isLoading) {
    return (
      <Modal title="" closeModal={() => { }}>
        <Loader />
      </Modal>
    )
  }

  return (
    <header className="text-base lg:text-sm">
      <div className={`z-10 bg-white items-center gap-x-2 px-4 max-w-screen-2xl mx-auto lg:flex lg:px-8 lg:static ${state ? "h-full fixed inset-x-0" : ""}`}>
        <div className="flex items-center justify-between py-3 lg:py-5 lg:block">
          <button type="button" onClick={() => { navigate('/dashboard') }}>
            <img
              src={LogoTemp}
              width={30}
              height={20}
              alt="Lampside Logo"
            />
          </button>
          <div className="lg:hidden">
            <button className="text-gray-500 hover:text-gray-800"
              onClick={() => setState(!state)}
            >
              {
                state ? (
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                    <path fillRule="evenodd" d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm8.25 5.25a.75.75 0 01.75-.75h8.25a.75.75 0 010 1.5H12a.75.75 0 01-.75-.75z" clipRule="evenodd" />
                  </svg>

                )
              }
            </button>
          </div>
        </div>
        <div className={`nav-menu flex-1 pb-28 mt-8 overflow-y-auto max-h-screen lg:block lg:overflow-visible lg:pb-0 lg:mt-0 ${state ? "" : "hidden"}`}>
          <ul className="items-center space-y-6 lg:flex lg:space-x-6 lg:space-y-0">
            <form onSubmit={(e) => e.preventDefault()} className='flex-1 items-center justify-start pb-4 lg:flex lg:pb-0'>
              {
                isLoggedIn ? <div className="flex items-center gap-1 px-2 border rounded-lg">
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                  </svg>
                  <input
                    type="text"
                    placeholder="Search WIP..."
                    className="w-full px-2 py-2 text-gray-500 bg-transparent rounded-md outline-none"
                  />
                </div> : null
              }
            </form>
            {
              navigation.map((item, idx) => {
                return (
                  <li key={idx}>
                    <button
                      className="block text-gray-700 hover:text-gray-900"
                      onClick={() => {
                        if (item.isAuth) {
                          if (item.path === '/login') {
                            navigate(item.path)
                            return
                          }
                          handleLogoutAll()
                          return
                        }
                        navigate(item.path)
                        return
                      }}
                    >
                      {item.title}
                    </button>
                  </li>
                )
              })
            }
            {/* <AvatarMenue /> */}
          </ul>
        </div>
      </div>
      {
        isLoggedIn ? (
          <nav className="border-b">
            <ul className="flex items-center gap-x-3 max-w-screen-2xl mx-auto px-4 overflow-x-auto lg:px-8">
              {
                submenuNav.map((item, idx) => {
                  return (
                    <li key={idx} className={`py-1 ${location.pathname === item.path ? "border-b-2 border-blue-600" : ""}`}>
                      <button
                        className="block py-2 px-3 rounded-lg text-gray-700 hover:text-gray-900 hover:bg-gray-100 duration-150"
                        onClick={() => {
                          navigate(item.path)
                        }}
                      >
                        {item.title}
                      </button>
                    </li>
                  )
                })
              }
            </ul>
          </nav>
        )
          : null
      }
    </header>
  )
}