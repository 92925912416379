import React from 'react';
import { useParams } from "react-router-dom";
import { useGetAllProjectsQuery } from '../../state/services';
import { buildAddress, currencyFormatter } from '../../utils';

export const tableHeaders = [
  'Project #',
  'Site Address',
  'Project Type',
  'Status',
  'Fees',
]

const createTableCell = (value) => {
  return value ? (
    <td className="group-hover:text-blue-600 pr-6 py-4 text-blue-600">
      <div>{value}</div>
    </td>
  ) : null
}

export const Sites = () => {
  const { site } = useParams()
  const projectsData = useGetAllProjectsQuery('')
  const projectsWithSite = projectsData.data.projects.filter((project) => {
    return project.site.toLowerCase() === decodeURIComponent(site).toLowerCase()
  })

  return (
    <section className="container px-4 mx-auto w-3/4">
      <div className="flex items-center justify-center max-w-screen-xl w-min md:w-full px-4 md:px-4">
        <div className="flex flex-col justify-center items-center w-full">
          <div className="max-w-lg">
            <h3 className="text-gray-800 text-2xl font-bold">
              Projects for: {decodeURIComponent(site)}
            </h3>
          </div>
          <div className="text-sm mt-12 w-full">
            <table className="w-full table-auto text-left">
              <thead className="text-gray-600 font-medium border-b">
                <tr>
                  {
                    tableHeaders.map((header, index) => {
                      return (
                        <th className={`py-4 ${index === 0 ? '' : 'pr-6'}`}>{header}</th>
                      )
                    })
                  }
                </tr>
              </thead>
              <tbody className="text-gray-600 divide-y">
                {
                  projectsWithSite.map((project) => {
                    const { builtAddress } = buildAddress({
                      unit: project.unit,
                      street_address: project.street_address,
                      city: project.city,
                      province: project.province,
                      country: project.country,
                      postal_code: project.postal_code,
                    })

                    return (
                      <tr onClick={() => { }}>
                        {createTableCell(project.project_number)}
                        {createTableCell(builtAddress)}
                        {createTableCell(project.project_type)}
                        {createTableCell(project.status)}
                        {createTableCell(currencyFormatter().format(project.fee))}
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section >
  )
}