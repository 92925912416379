import React, { createContext, useContext, useState } from 'react';
import { Project } from './types';

export const PROJECT_STATUS_OPTIONS: Project['status'][] = ['ALL', 'PROPOSAL SENT', 'PENDING PROPOSAL', 'ACTIVE', 'COMPLETE', 'LOST'];

export type Account = {
  id: number;
  name: string;
  poc: string;
  unit?: string;
  street_address?: string;
  city?: string;
  province?: string;
  country?: string;
  postal_code?: string;
  email: string;
  phone: string;
  created_at?: string;
  updated_at?: string;
}

// key should be client id
type GlobalState = {
  [k: string]: {
    client: Account;
    projects: Project[];
  }
}

type AccountPageData = {
  client: Account;
  projects: Project[];
}

const defaultState = {
  data: {},
  updateGlobalState: null,
  isLoading: false,
  setIsLoading: null
}


export const GlobalContext = createContext(defaultState);

export const GlobalContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [globalState, setGlobalState] = useState<GlobalState>(defaultState.data)
  const [isLoading, setIsLoading] = useState(false)

  const updateGlobalState = (jsonData: AccountPageData) => {
    if (!globalState[jsonData.client.id]) {
      setGlobalState({
        ...globalState,
        [jsonData.client.id]: {
          client: jsonData.client,
          projects: jsonData.projects
        }
      })
    }
  }

  const value = {
    data: globalState,
    updateGlobalState,
    isLoading,
    setIsLoading,
  };

  return (
    <GlobalContext.Provider value={value}>
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(GlobalContext);
};
