import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Info } from '../../components/info/Info'
import { useUpdatePassword } from '../../hooks'
import { isUserEmpty, selectUser } from '../../state/services'

export const User = () => {
  const userData = useSelector(selectUser)
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [updatedConfirm, setUpdatedConfirm] = useState(false)
  const { updatePassword } = useUpdatePassword()
  const handleEditUser = () => {
    alert('edit user coming soon...')
  }

  if (isUserEmpty(userData)) {
    return <div>Error loading User data, please contact customer support or login again.</div>
  }

  return (
    <div className="w-full">
      <Info
        heading={{ key: 'User', value: `${userData.first_name} ${userData.last_name}` }}
        information={{ email: userData.email }}
        handleInfoEdit={handleEditUser}
      />
      <div className="mx-auto max-w-80">
        <h3 className="text-xl text-left">Change Password:</h3>
        <div className="w-full min-w-64 mx-auto flex flex-col gap-4">
          <div className="w-full max-w-80 flex flex-col gap-1">
            <label className="text-sm text-gray-700 text-left" htmlFor="current-password">
              <span>Current password:</span>
            </label>
            <input
              value={currentPassword}
              onChange={(e) => {
                setUpdatedConfirm(false)
                setCurrentPassword(e.target.value)
              }}
              type="password"
              name="current-password"
              className="block w-full px-4 py-3 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
            />
          </div>
          <div className="w-full max-w-80 flex flex-col gap-1">
            <label className="text-sm text-gray-700 text-left" htmlFor="new-password">
              <span>New password:</span>
            </label>
            <input
              value={newPassword}
              onChange={(e) => {
                setUpdatedConfirm(false)
                setNewPassword(e.target.value)
              }}
              type="password"
              name="new-password"
              className="block w-full px-4 py-3 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
            />
          </div>
          <div className="w-full max-w-80 flex flex-col gap-1">
            <label className="text-sm text-gray-700 text-left" htmlFor="confirm-new-password">
              <span>Confirm new password:</span>
            </label>
            <input
              value={confirmPassword}
              onChange={(e) => {
                setUpdatedConfirm(false)
                setConfirmPassword(e.target.value)
              }}
              type="password"
              name="confirm-new-password"
              className="block w-full px-4 py-3 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
            />
          </div>
        </div>
        <button
          type="button"
          onClick={async () => {
            if (newPassword !== confirmPassword) {
              alert('The passwords do not match!')
              return
            }
            const message = await updatePassword({ current_password: currentPassword, new_password: newPassword })
            if (message === 'Password updated successfully') {
              setCurrentPassword('')
              setNewPassword('')
              setConfirmPassword('')
              setUpdatedConfirm(true)
              return
            }

            alert('Current Password is incorrect or there was an error. Please try again.')
          }}
          className="mt-6 relative w-full px-2 py-2 text-sm font-medium text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
        >
          Change password
        </button>
        {
          updatedConfirm ? <div className="text-base text-green-600">Password successfully updated!</div> : null
        }
      </div>
    </div>
  )

}