import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { Project as ProjectType } from 'state/types';
import { buildAddress, createOptions, currencyFormatter, getApiUrl } from "../../utils";
import { tableHeaders } from "../projects/utils";

const createTableCell = (value) => {
  return value ? (
    <td className="group-hover:text-blue-600 pr-6 py-4 text-blue-600">
      <div>{value}</div>
    </td>
  ) : null
}

export const Project = () => {
  const navigate = useNavigate()
  const { projectId } = useParams()
  const [error, setError] = useState(false)
  const [projectData, setProjectData] = useState<{ account_name: string; project: ProjectType } | null>(null)

  // TODO: RTK for project
  useEffect(() => {
    const getProjectData = async () => {
      const url = getApiUrl(`projects/${projectId}`)
      const data = await fetch(url, createOptions())

      if (!data.ok) {
        setProjectData(null)
        setError(true)
      }

      const jsoned = await data.json()

      setProjectData(jsoned.data)
    }

    getProjectData()
  }, [])

  if (error) {
    return (<div>
      <h1>Project not found</h1>
      <button
        type="button"
        onClick={() => {
          navigate('/projects')
        }}
        className="inline-flex items-center justify-center gap-1 py-2 px-3 font-medium text-sm text-center text-white bg-blue-600 hover:bg-sky-700 active:bg-sky-500 rounded-lg sm:mt-0"
      >Click here to go back to Projects page</button>
    </div>)
  }

  if (!projectData) {
    return (<div>Loading...</div>)
  }

  const { builtAddress } = buildAddress({
    unit: projectData.project.unit,
    street_address: projectData.project.street_address,
    city: projectData.project.city,
    province: projectData.project.province,
    country: projectData.project.country,
    postal_code: projectData.project.postal_code,
  })

  return (
    <>
      {
        projectData ? <section className="container px-4 mx-auto w-3/4">
          <div className="flex items-center justify-center max-w-screen-xl w-min md:w-full px-4 md:px-4">
            <div className="flex flex-col justify-center items-center w-full">
              <div className="max-w-lg">
                <h3 className="text-gray-800 text-2xl font-bold">
                  Project ID {projectData.project.project_number} for {projectData.account_name}
                </h3>
              </div>
              <div className="text-sm mt-12 w-full">
                <table className="w-full table-auto text-left">
                  <thead className="text-gray-600 font-medium border-b">
                    <tr>
                      {
                        tableHeaders.map((header, index) => {
                          return (
                            <th className={`py-4 ${index === 0 ? '' : 'pr-6'}`}>{header}</th>
                          )
                        })
                      }
                    </tr>
                  </thead>
                  <tbody className="text-gray-600 divide-y">
                    {/* className="hover:text-blue-600 hover:bg-gray-50 cursor-pointer" */}
                    <tr onClick={() => { }}>
                      {createTableCell(projectData.project.project_number)}
                      {createTableCell(projectData.project.site)}
                      {createTableCell(builtAddress)}
                      {createTableCell(projectData.project.project_type)}
                      {createTableCell(projectData.project.status)}
                      {createTableCell(currencyFormatter().format(projectData.project.fee))}
                      {/* {createTableCell(projectData.project.invoiced_fee)} */}
                      {/* {createTableCell(`${calcPercentInvoiced(projectData.project.invoiced_fee, projectData.project.fee)}%`)} */}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section > : null
      }
    </>

  )
}
