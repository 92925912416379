import React, { useEffect, useState } from 'react';
import {
  CitySelect,
  CountrySelect,
  StateSelect
} from "react-country-state-city";
import { Project } from 'state/types';
import { PostalCodeInput } from '../../components/location';
import { Modal } from '../../shared';
import { TAILWIND_CLASSES, populateCountryProvinceCity } from '../../utils';
import { useListenForKeyboardPress } from '../../utils/hooks';

export type Address = {
  unit: string;
  street_address: string;
  city: string;
  province: string;
  postal_code: string;
  country: string;
}

type Props = {
  project: Project
  saveAddress: (addressInfo: Address) => void
  closeModal: () => void;
}

export const EditAddressModal = ({ project, saveAddress, closeModal }: Props) => {
  // TODO: error messages for invalid entries on client side
  const [errors, setErrors] = useState({
    unit: '',
    street_address: '',
    city: '',
    province: '',
    postal_code: '',
    country: '',
  })
  const [countryObject, setCountryObject] = useState({ id: 0 });
  const [stateObject, setStateObject] = useState({ id: 0 });
  const [cityObject, setCityObject] = useState({ id: 0 });
  const initState = {
    unit: project.unit,
    street_address: project.street_address,
    city: project.city,
    province: project.province,
    postal_code: project.postal_code,
    country: project.country
  }
  const [addressToUpdate, setAddressToUpdate] = useState(initState)

  const hasError = Object.values(errors).some(value => value !== '')

  useListenForKeyboardPress('Escape', () => {
    closeModal()
  })

  useEffect(() => {
    populateCountryProvinceCity({
      country: project.country,
      province: project.province,
      city: project.city,
      setCountry: setCountryObject,
      setProvince: setStateObject,
      setCity: setCityObject,
    })
  }, [])

  return (
    <Modal title="Edit Address" closeModal={closeModal}>
      <div className="w-[450px]">
        <label className="text-sm text-gray-700" htmlFor="unit">
          <input
            type="text"
            name="unit"
            id="unit"
            placeholder="Unit"
            value={addressToUpdate.unit}
            className="block w-full px-4 py-3 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
            onChange={(e) => {
              setAddressToUpdate({
                ...addressToUpdate,
                unit: e.target.value
              })
            }}
            onBlur={async (e) => {
              setAddressToUpdate({
                ...addressToUpdate,
                unit: e.target.value
              })
            }} />
        </label>

        <label className="text-sm text-gray-700" htmlFor="street_address">
          <input
            type="string"
            name="street_address"
            id="street_address"
            placeholder="Street Address"
            value={addressToUpdate.street_address}
            className="block w-full px-4 py-3 mt-4 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
            onChange={(e) => {
              setAddressToUpdate({
                ...addressToUpdate,
                street_address: e.target.value
              })
            }}
            onBlur={(e) => {
              setAddressToUpdate({
                ...addressToUpdate,
                street_address: e.target.value
              })
            }}
          />
        </label>
        <label
          className="text-sm text-gray-700"
          htmlFor="country"
        >
          <CountrySelect
            id="country"
            defaultValue={countryObject?.id === 0 ? undefined : countryObject}
            showFlag={false}
            onChange={(e) => {
              setAddressToUpdate({
                ...addressToUpdate,
                country: e.name,
              })
              setCountryObject(e)
            }}
            placeHolder="Select Country"
          />
        </label>
        <label className="text-sm text-gray-700" htmlFor="province">
          <StateSelect
            id="province"
            countryid={countryObject?.id}
            defaultValue={stateObject?.id === 0 ? undefined : stateObject}
            showFlag={false}
            onChange={(e) => {
              setAddressToUpdate({
                ...addressToUpdate,
                province: e.name,
              })
              setStateObject(e)
            }}
            placeHolder="Select State"
          />
        </label>
        <label
          className="text-sm text-gray-700"
          htmlFor="city"
        >
          <CitySelect
            id="city"
            countryid={countryObject?.id}
            stateid={stateObject?.id}
            defaultValue={cityObject?.id === 0 ? undefined : cityObject}
            showFlag={false}
            onChange={(e) => {
              setAddressToUpdate({
                ...addressToUpdate,
                city: e.name,
              })
              setCityObject(e)
            }}
            placeHolder="Select City"
          />
        </label>
        <label
          className="text-sm text-gray-700"
          htmlFor="postal_code"
        >
          <PostalCodeInput
            initValue={initState.postal_code}
            label="postal_code"
            isCanada={addressToUpdate.country === 'Canada'}
            updatePostalCode={(postalCode) => {
              setAddressToUpdate({
                ...addressToUpdate,
                postal_code: postalCode,
              })
            }}
            setError={(errorMessage: string) => {
              setErrors({
                ...errors,
                postal_code: errorMessage,
              })
            }}
          />
          {errors.postal_code !== '' ? <div style={{ color: 'red ' }}>{errors.postal_code}</div> : null}
        </label>
        <div className="mt-4 sm:flex sm:items-center sm:-mx-2">
          <button
            onClick={(e) => {
              e.stopPropagation();
              saveAddress(addressToUpdate)
              closeModal()
            }}
            type="button"
            disabled={hasError}
            className={`w-full px-4 py-2 mt-3 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-md sm:mt-0 sm:w-1/2 sm:mx-2 hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40${hasError ? ` ${TAILWIND_CLASSES.disabled}` : ''}`}
          >
            Save Address
          </button>
          <button onClick={(e) => { e.stopPropagation(); closeModal() }} type="button" className="w-full px-4 py-2 text-sm font-medium tracking-wide text-gray-700 capitalize transition-colors duration-300 transform border border-gray-200 rounded-md sm:w-1/2 sm:mx-2 hover:bg-gray-100 focus:outline-none focus:ring focus:ring-gray-300 focus:ring-opacity-40">
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  )
}