import { useEffect, useRef } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

export const useListenForKeyboardPress = (key: string, callback?: () => void) => {
  useEffect(() => {
    const handleCloseModalShortcut = (e: KeyboardEvent) => {
      if (e.key === key) {
        callback?.()
      }
    }

    document.addEventListener('keydown', handleCloseModalShortcut)

    return () => {
      document.removeEventListener('keydown', handleCloseModalShortcut)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export const useListenForOutsideClick = (callback: () => void) => {
  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [callback]);

  return { wrapperRef }
}

export const useGetNavigation = () => {
  const navigate = useNavigate()
  const location = useLocation()
  return {
    navigate,
    location
  }
}