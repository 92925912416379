import React from 'react';
import './search-input.css';

type Props = {
  value: string;
  disabled?: boolean;
  onChange: (e) => void;
  onBlur?: () => void;
}

export const SearchInput = ({ value, disabled, onChange }: Props) => {
  return (
    <div className="relative flex w-full">
      <input
        type="text"
        disabled={disabled}
        placeholder="Search (UI to be updated)"
        className={`search-input w-full py-3 px-4 text-gray-500 border rounded-md outline-none bg-gray-50 text-base focus:bg-white focus:border-blue-600`}
        value={value}
        onChange={onChange}
      />
      {
        value
          ? (
            <div className="absolute top-[-4px] right-[-4px]">
              <span className="relative flex h-4 w-4">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                <span className="relative inline-flex rounded-full h-4 w-4 bg-sky-500"></span>
              </span>
            </div>
          )
          : null
      }
    </div>
  )
}