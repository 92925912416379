import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore
} from 'redux-persist'
import { instanceProjectSlice } from './instances'
import { persistConfig } from './persist'
import { accounts, authSlice, awardeds, columns, invoices, payments, projects, users } from './services'

const middleware = [
  // authSlice.
  projects.middleware,
  accounts.middleware,
  users.middleware,
  invoices.middleware,
  columns.middleware,
  payments.middleware,
  awardeds.middleware,
  // checkAuth, // Order matters here; the default RTK query middlewares probably doesn't return next(), hence an issue when checkAuth is at the beginning
]

const rootReducer = combineReducers({
  // Add the generated reducer as a specific top-level slice
  [projects.reducerPath]: projects.reducer,
  [accounts.reducerPath]: accounts.reducer,
  [authSlice.reducerPath]: authSlice.reducer,
  [users.reducerPath]: users.reducer,
  [invoices.reducerPath]: invoices.reducer,
  [columns.reducerPath]: columns.reducer,
  [payments.reducerPath]: payments.reducer,
  [instanceProjectSlice.reducerPath]: instanceProjectSlice.reducer,
  [awardeds.reducerPath]: awardeds.reducer,
})

const reduxPersistActions = [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER];

export const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  // TODO: middleware, for caching, etc
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [...reduxPersistActions],
      },
    }).concat(middleware)
  }
})

export const persistor = persistStore(store)

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)

export type IRootReducer = ReturnType<typeof rootReducer>
// Below for configureStore, instead of combineReducers
export type IRootState = ReturnType<typeof store.getState>

export const resetStore = () => {
  store.dispatch({ type: 'RESET' })
  // TODO: delete redux-persist data
  persistor.pause()
  persistor.flush().then(() => {
    return persistor.purge();
  });
}