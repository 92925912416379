import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import {
  Outlet,
  RouterProvider,
  createBrowserRouter
} from "react-router-dom";
import { PersistGate } from 'redux-persist/integration/react';
import "tw-elements-react/dist/css/tw-elements-react.min.css";
import { App } from './App';
import './App.css';
import { Account } from './components/account';
import { Nav } from './components/nav';
import './index.css';
import { Accounts } from './pages/accounts';
import { CreateFirm } from './pages/create-firm';
import { CsvUploadPage } from './pages/csv-upload';
import { Dashboard } from './pages/dashboard';
import { Firm } from './pages/firm';
import { Invite } from './pages/invite';
import { Login } from './pages/login';
import { Project } from './pages/project';
import { Projects } from './pages/projects';
import { Sites } from './pages/sites';
import { User } from './pages/user';
import { persistor, store } from './state/store';

const NavbarWrapper = () => (
  <>
    <Nav />
    <div className="py-4" />
    <div className="App-div">
      <Outlet />
    </div>
  </>
)

const router = createBrowserRouter([
  // TODO: Update redirect to dashboard
  {
    path: "/",
    element: <NavbarWrapper />,
    children: [
      {
        path: '/',
        element: <Projects />,
      }
    ]
  },
  {
    path: "/",
    element: <NavbarWrapper />,
    children: [
      {
        path: '/projects',
        element: <Projects />,
      }
    ]
  },
  {
    path: "/",
    element: <NavbarWrapper />,
    children: [
      {
        path: '/dashboard',
        element: <Dashboard />,
      }
    ]
  },
  {
    path: "/",
    element: <NavbarWrapper />,
    children: [
      {
        path: '/firm',
        element: <Firm />,
      }
    ]
  },
  {
    path: "/",
    element: <NavbarWrapper />,
    children: [
      {
        path: '/user',
        element: <User />,
      }
    ]
  },
  // TODO: USER CAN'T GET TO THIS YET, MAYBE RM
  {
    path: "/",
    element: <NavbarWrapper />,
    children: [
      {
        path: "/sites/:site",
        element: <Sites />
      }
    ]
  },
  {
    path: "/",
    element: <NavbarWrapper />,
    children: [
      {
        path: "/accounts",
        element: <Accounts />
      }
    ]
  },
  {
    path: "/",
    element: <NavbarWrapper />,
    children: [
      {
        path: "/account",
        element: <Account />
      }
    ]
  },
  {
    path: "/",
    element: <NavbarWrapper />,
    children: [
      {
        path: '/project/:projectId',
        element: <Project />
      }
    ]
  },
  {
    path: "/",
    element: <NavbarWrapper />,
    children: [
      {
        path: '/login',
        element: <Login />
      }
    ]
  },
  {
    path: '/',
    element: <NavbarWrapper />,
    children: [
      {
        path: '/create-firm',
        element: <CreateFirm />
      }
    ]
  },
  {
    path: '/',
    element: <NavbarWrapper />,
    children: [
      {
        path: '/csv-upload-test',
        element: <CsvUploadPage />
      }
    ]
  },
  {
    path: '/',
    // element: <Invite />,
    children: [
      {
        path: '/invitations/accept/:token/:firmName/:inviter',
        element: <Invite />
      }
    ]
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App>
          {
            <RouterProvider router={router} />
          }
        </App>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
