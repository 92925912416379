
import {
  GetCity,
  GetCountries,
  GetState
} from "react-country-state-city";

export const TEMP_setCookie = (name, val) => {
  // let expires = "";
  // if (days) {
  //   var date = new Date();
  //   date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  //   expires = "; expires=" + date.toUTCString();
  // }
  document.cookie = name + "=" + JSON.stringify(val) + "; path=/"
}

export const TEMP_getCookie = (name) => {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    // Check if this cookie has the specified name
    if (cookie.startsWith(name + '=')) {
      // Get the value of the cookie
      const cookieValue = cookie.substring(name.length + 1);
      // Parse the cookie value as JSON and return
      return JSON.parse(cookieValue);
    }
  }
  // Return null if the cookie with the specified name is not found
  return null;
}

export const getApiUrl = (endpoint) => {
  return `${process.env.REACT_APP_API_URL}/${endpoint}`
}

// TODO: Refactor this stuff....
export const createOptions = (method = 'GET', requestOptions?: {
  credentials?: RequestCredentials;
  body?: Record<string, any>;
}): RequestInit => {
  const headers = new Headers();
  headers.append('x-api-key', process.env.REACT_APP_X_API_KEY)
  headers.append('Content-Type', 'application/json')

  const options: RequestInit = {
    method,
    headers,
    // TODO: refactor so credentials are included by default, but should be excluded
    credentials: requestOptions?.credentials ?? 'include',
    ...(requestOptions?.body ? { body: JSON.stringify(requestOptions.body) } : undefined)
  }

  return options
}

export const buildAddress = ({ unit, street_address, city, province, postal_code, country }: { unit?: string; street_address?: string; city?: string; province?: string; postal_code: string; country: string; }) => {
  const unitPart = unit ? `${unit} - ` : '';
  const address = `${unitPart}${street_address ?? ''}`.trim();

  const cityProvincePostalParts = [];
  if (city) cityProvincePostalParts.push(`${city}`);
  if (province) cityProvincePostalParts.push(`${province}`);
  if (postal_code) cityProvincePostalParts.push(`${postal_code}`);
  const cityProvincePostal = cityProvincePostalParts.join(' ');

  const builtAddress = `${address ? `${address}. ` : ''}${cityProvincePostal ? `${cityProvincePostal}, ` : ''}${country ?? ''}`

  return { builtAddress, address, cityProvincePostal, country }
}

export const calcPercentInvoiced = (invoicedFee: number, totalFee: number): number => {
  // Divide by 0 returns NaN
  if (totalFee === 0) {
    return 0
  }
  // Takes a number like 25.456, * 10000 means rounded to 2545 via Math.round. then divide by 100 to get 25.45 for percentage
  return Math.round(invoicedFee / totalFee * 10000) / 100
}

export const emailValidator = (email: string) => {
  const validator = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return String(email).toLowerCase().match(validator)
}

export const currencyFormatter = (countryCode?: string) => {
  const code = countryCode ?? 'en-CA'
  try {
    const formatter = new Intl.NumberFormat(code, {
      style: 'currency',
      // TODO: mapping of currency and countryCode?
      currency: code === 'en-CA' ? 'CAD' : 'USD',
      currencyDisplay: 'narrowSymbol',
    });

    return {
      format: formatter.format
    }
  } catch (err) {
    return {
      format: (currency) => {
        console.error(`utils:currencyFormatter:: ${JSON.stringify(err)}`)
        return currency
      },
    }
  }
}

export const sortAlphabeticallyObjects = <T extends Record<string, any>[]>(arr: T, sortBy: string) => {
  return arr.sort((a, b) => {
    if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
      return -1;
    }
    if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
      return 1;
    }
    return 0;
  });

}

export const sortAlphabetically = <T extends string[] | number[]>(arr: T) => {
  return arr.slice().sort((a, b) => {
    if (a.toLowerCase() < b.toLowerCase()) {
      return -1;
    }
    if (a.toLowerCase() > b.toLowerCase()) {
      return 1;
    }
    return 0;
  });
}

export const setupKeyboardListener = (key: string, callback: () => void) => {
  const handleKeyboardPress = (e) => {
    if (e.key === key) {
      callback()
    }
    return
  }

  const attachListener = () => {
    document.addEventListener('keydown', handleKeyboardPress)
  }

  const removeListener = () => {
    document.removeEventListener('keydown', handleKeyboardPress)
  }

  return {
    attachListener,
    removeListener,
  }
}

export const populateCountryProvinceCity = async ({ country, province, city, setCountry, setProvince, setCity }) => {
  let preSelectedCountry
  let preSelectedState
  if (country) {
    const countriesList = await GetCountries()

    preSelectedCountry = countriesList.find((countryFromList) => {
      if (country.toLowerCase() === 'usa') {
        if (countryFromList.name === 'United States') {
          return true
        }
      }
      return countryFromList.name.toLowerCase() === country.toLowerCase()
    })

    if (preSelectedCountry) {
      setCountry(preSelectedCountry);
    }
  }

  // TODO: should select just state code or name, not both?
  if (province) {
    const statesList = await GetState(preSelectedCountry?.id)
    console.log({ province, statesList })
    preSelectedState = statesList.find((provinceFromList) => {
      return provinceFromList.state_code.toLowerCase() === province.toLowerCase() || provinceFromList.name.toLowerCase() === province.toLowerCase()
    })

    if (preSelectedState) {
      setProvince(preSelectedState);
    }
  }

  if (city) {
    const citiesList = await GetCity(preSelectedCountry?.id, preSelectedState?.id)
    const preSelectedCity = citiesList.find((cityFromList) => {
      return cityFromList.name.toLowerCase() === city.toLowerCase()
    })

    if (preSelectedCity) {
      setCity(preSelectedCity);
    }

  }
}

export const TAILWIND_CLASSES = {
  disabled: '!bg-gray-400 !cursor-not-allowed !hover:bg-gray-400 !focus:ring-gray-300',
}
export * from './colors'
