import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AddAccountModal } from '../../components/account/add-account-modal'
import { EditAccountModal } from '../../components/account/edit-account-modal'
import { Modal } from '../../shared'
import { selectFirm, useGetAllAccountsQuery } from '../../state/services'
import { buildAddress } from '../../utils'

export const Accounts = () => {
  const [isAddAccount, setIsAddAccount] = useState(false)
  const [editAccount, setEditAccount] = useState<number | undefined>()
  const { data, isError, isLoading, isSuccess } = useGetAllAccountsQuery('accounts-page')
  const firm = useSelector(selectFirm)
  const navigate = useNavigate()

  if (isLoading) {
    return (
      <div>Accounts data loading...</div>
    )
  }

  if (isError || !isSuccess) {
    return (
      <div>Accounts data loading failed</div>
    )
  }

  return (
    <>
      {isAddAccount
        ? (<Modal title="Add a new account" closeModal={() => { setIsAddAccount(false) }}>
          <AddAccountModal setIsAddingAccount={setIsAddAccount} />
        </Modal>)
        : null
      }
      {
        editAccount !== undefined
          ? <EditAccountModal account={data.find((account) => account.id === editAccount)} closeModal={() => { setEditAccount(undefined) }} />
          : null
      }
      <div className="max-w-screen-2xl mx-auto px-4 flex flex-col justify-center align-center">
        <div>
          <h3 className="text-gray-800 text-xl font-bold sm:text-2xl">
            Accounts for {firm.name}
          </h3>
          <div className="mt-3 md:mt-0">
            <button
              onClick={() => { setIsAddAccount(true) }}
              className="inline-block px-4 py-2 text-white duration-150 font-medium bg-blue-600 rounded-lg hover:bg-sky-700 active:bg-sky-500 md:text-sm"
            >
              Add Account
            </button>
          </div>
        </div>
        <div className="mt-12 shadow-sm border rounded-lg overflow-x-auto">
          <table className="w-full table-auto text-sm text-left">
            <thead className="bg-gray-50 text-gray-600 font-medium border-b">
              <tr>
                <th className="py-3 px-6">Account</th>
                <th className="py-3 px-6">Point of Contact</th>
                <th className="py-3 px-6">Email</th>
                <th className="py-3 px-6">Phone Number</th>
                <th className="py-3 px-6">Address</th>
                <th className="py-3 px-6"></th>
                <th className="py-3 px-6"></th>
              </tr>
            </thead>
            <tbody className="text-gray-600 divide-y">
              {
                data.map((item, idx) => {
                  const { address, cityProvincePostal, country } = buildAddress({ unit: item.unit, street_address: item.street_address, city: item.city, province: item.province, country: item.country, postal_code: item.postal_code })
                  return (
                    // TODO: fix clicking edit/delete navigate
                    <tr
                      // className="hover:text-blue-600 hover:bg-gray-50"
                      key={idx}
                    >
                      <td className="px-6 py-4 whitespace-nowrap">
                        <button
                          type="button"
                          className="hover:text-blue-600"
                          onClick={(e) => {
                            console.log(item)
                            navigate(`/account?accountId=${item.id}`)
                          }}
                        >
                          {item.name}
                        </button>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">{item.poc}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{item.email}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{item.phone}</td>
                      <td className="flex flex-col px-6 py-4 whitespace-nowrap">
                        {
                          address ?
                            <>
                              <span>{address}.</span>
                              <span>{cityProvincePostal ? cityProvincePostal : ''}{country ? `, ${country}` : null}</span>
                            </>
                            : null
                        }
                      </td>
                      <td className="text-right px-6 whitespace-nowrap">
                        <button
                          type="button"
                          onClick={() => { setEditAccount(item.id) }}
                          className="py-2 px-3 font-medium text-blue-600 hover:text-sky-700 duration-150 hover:bg-gray-50 rounded-lg"
                        >
                          Edit
                        </button>
                        <button onClick={() => alert('Coming soon...')} className="py-2 leading-none px-3 font-medium text-red-600 hover:text-red-500 duration-150 hover:bg-gray-50 rounded-lg">
                          Delete
                        </button>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}